.project-summary-text {
  margin: 15px;
}

.project-summary-text.highlight {
  font-weight: bold;
}

.project-summary-text.emphasis {
  font-style: italic;
}

.project-summary-icons {
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  #projects-content {
    display: flex;
    justify-content: space-around;
  }

  .large-project-display,
  .small-project-display {
    margin: 30px auto;
    padding: 0 20px;
    flex: 1 0 calc(960px / 2 - 40px);
    /* The line above should be flex: 1 0 calc(var(--content-width) / 2);
      * but this does not compile (even though the css is valid).
      */
  }
}

@media only screen and (min-width: 1280px) {
  .project-summary-text {
    line-height: 1.4em;
  }
}
