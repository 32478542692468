#falling {
  background-color: rgb(121, 102, 87); /* From rocks at bottom of image */
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.99)),
    url('../images/ChicagoSkylineHorizontal.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
}

#falling-placeholder {
  height: 0;
}

#about-stripecard-primary {
  display: none;
}

#about-img-large-screen {
  display: none;
}

@media only screen and (min-width: 600px) {
  #falling-placeholder {
    height: 60vw;
  }
}

@media only screen and (min-width: 1024px) {
  #falling-placeholder {
    height: 40vw;
  }
}

@media only screen and (min-width: 1280px) {
  #falling-placeholder {
    height: 32vw;
  }
}
