#contact-content {
  margin: 40px 0;
}

@media only screen and (min-width: 1024px) {
  .contact-btn {
    margin: 0 10px;
  }
}

.icons {
  text-align: center;
  color: var(--cerulean-40);
  margin: 30px auto;
}

.icons#lg {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .icons#sm {
    display: none;
  }

  .icons#lg {
    display: block;
  }
}
