/*
 * Font Sizes from http://type-scale.com/?size=16&scale=1.500&text=A%20Visual%20Type%20Scale&webfont=Cabin&font-family=%27Cabin%27%2C%20serif&font-weight=400&font-family-headers=&font-weight-headers=inherit&background-color=white&font-color=%23333
 * Colors from https://www.ibm.com/design/v1/language/resources/color-library/
 */
:root {
  --typescale-1: 3.375em;
  --typescale-2: 2.25em;
  --typescale-3: 1.5em;
  --typescale-4: 1em;
  --typescale-5: 0.667em;

  --cerulean-1: #deedf7;
  --cerulean-20: #95c4f3;
  --cerulean-40: #009bef;
  --cerulean-60: #175d8d;
  --peach-40: #fe6100;
  --cool-gray-30: rgb(159, 167, 167);

  --content-width: 290px;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: 'Source Sans Pro', sans-serif;
}

h1 {
  font-size: var(--typescale-1);
  margin: 0;
}

h2 {
  font-size: var(--typescale-2);
  margin: 0;
}

h3 {
  font-size: var(--typescale-3);
  margin: 0;
}

h4 {
  font-size: var(--typescale-4);
  margin: 0;
}

p {
  font-size: var(--typescale-4);
  margin: 0;
}

#root {
  height: 100%;
}

@media only screen and (min-width: 600px) {
  :root {
    --content-width: 580px;
  }
}

@media only screen and (min-width: 1024px) {
  :root {
    --content-width: 960px;
  }
}
