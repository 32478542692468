.content-width-wrapper {
  max-width: var(--content-width);
  margin: auto;
}

.page-text {
  margin: 15px 0;
}

.page {
  width: 100%;
}

.content-page {
  background-color: white;
  padding: 10px 0;
}
